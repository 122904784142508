import React from "react"

import Layout from "../components/Layout/Layout"
import Hero from "../components/Home/Hero/Hero"
import Content from "../components/Home/Content/Content"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { reCaptchaKey } from "./../components/Variables"


import "../../i18n"
import i18n from "i18next"


const IndexPage = () => {
  i18n.changeLanguage("de")  

  return (
    <Layout
      seo={{
        title: "Kostenloses SEO-Audit Ihrer Webseite • WeNet.pl",
        description:
          "Nutzen Sie ein kostenloses SEO-Audit und lassen Sie Ihre Webseite von der WeNet optimieren! • Wir helfen Ihnen bei der Anpassung Ihrer Webseite an die Anforderungen von Google",
        hreflangs: {
          pl: "",
          en: "en/",
          de: "de/",
        },
      }}
      isHome={true}
    >
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
        <Hero />
      </GoogleReCaptchaProvider>
      <Content />
    </Layout>
  )
}

export default IndexPage
